import React, { useState } from "react";
import Navbar from "../../Components/Navbar/navbar";
import SideMenu from "../../Components/Sidebar/sidebar";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';

function Leaves() {
   const [selectedSection, setSelectedSection] = useState("new");
   const [dropdownOpen, setDropdownOpen] = useState(false);

   const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
   };

   const handleDropdownItemClick = (section) => {
      setSelectedSection(section);
      setDropdownOpen(false); 
   };

   const handleAceept =()=>{
      window.confirm("Are You Sure to Accept this Leave ?")
   }

   const handelDelete =()=>{
      window.confirm("Are You Sure to decline this Leave request ?")
   }

   const getButtonText = () => {
      switch (selectedSection) {
         case "new":
            return "New Request";
         case "approved":
            return "Approved";
         case "rejected":
            return "Rejected";
         default:
            return "Select an option";
      }
   };

   return (
      <div className="flex w-full h-screen relative">
         <SideMenu />
         <div className="lg:w-[81%] max-sm:w-[100%] overflow-scroll scrollbar-hide px-3">
            <Navbar />
            <div className="lg:px-2 py-6 relative">
               <div className="flex items-center max-sm:mt-10 mt-10 relative">
                  <h1 className="text-2xl max-sm:text-xl font-semibold">Leave Management</h1>
                  <div className="relative">
                     <button
                        onClick={toggleDropdown}
                        className="p-1 ml-5 px-2 rounded-xl border border-gray-300 bg-white"
                     >
                        {getButtonText()}<ArrowDropDownIcon />
                     </button>
                     {dropdownOpen && (
                        <ul className="absolute right-0 mt-2  w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                           <li>
                              <button
                                 onClick={() => handleDropdownItemClick("new")}
                                 className={`block p-2 w-full text-left rounded-lg hover:bg-gray-200 ${selectedSection === "new" ? "bg-gray-200" : ""
                                    }`}
                              >
                                 New Request
                              </button>
                           </li>
                           <li>
                              <button
                                 onClick={() => handleDropdownItemClick("approved")}
                                 className={`block p-2 w-full text-left rounded-lg hover:bg-gray-200 ${selectedSection === "approved" ? "bg-gray-200" : ""
                                    }`}
                              >
                                 Approved
                              </button>
                           </li>
                           <li>
                              <button
                                 onClick={() => handleDropdownItemClick("rejected")}
                                 className={`block p-2 w-full text-left rounded-lg hover:bg-gray-200 ${selectedSection === "rejected" ? "bg-gray-200" : ""
                                    }`}
                              >
                                 Rejected
                              </button>
                           </li>
                        </ul>
                     )}
                  </div>
               </div>
               <hr className="mt-5" />
               <div className="mt-8">
                  {selectedSection === "new" && (
                     <div className="">
                        <div className="lg:flex sm:flex justify-between">
                           <div>
                              <h1 className="text-xl max-sm:text-lg font-bold max-sm:font-medium mb-2 max-sm:mb-0">New Leave Requests</h1>
                              <p className="mb-6 max-sm:mb-2 text-gray-600 max-sm:text-[12px]">This Is Sub Description</p>
                           </div>
                           <div className="flex max-sm:overflow-scroll scrollbar-hide space-x-3 max-sm:space-x-2 mb-6 font-medium text-[14px] max-sm:text-[12px]">
                              <div className="relative ">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Filter By Employee</option>
                                    <option>new Employee</option>
                                    <option>Old employee</option>
                                 </select>
                              </div>
                              <div className="relative">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Filter By Group</option>
                                    <option>Construction Group</option>
                                    <option>Electric Group</option>
                                 </select>
                              </div>
                              <div className="relative">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Date</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* Table data */}
                        <div className="mt-6">
                           <div className="overflow-x-auto">
                              <table className="w-full bg-white">
                                 <thead className="bg-gray-100 ">
                                    <tr>
                                       <th className="py-3 max-sm:py-0  max-sm:px-14 ">S.NO</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14 ">Emp Name</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Group</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Leave Dates</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">No of days</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Reason</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14">Attachments</th>
                                       <th className="py-3 max-sm:py-0 max-sm:px-14"> Actions</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr className="border-b text-center">
                                       <td className="py-3 px-6 ">1</td>
                                       <td className="py-3 px-6 ">Anil</td>
                                       <td className="py-3 px-6 ">Construction</td>
                                       <td className="py-3 px-6  ">25-09-24 to 05-10-24</td>
                                       <td className="py-3 px-6 ">10</td>
                                       <td className="py-3 px-6  underline"> <a >View</a></td>
                                       <td className="py-3 px-6  underline"> <a >Details</a></td>
                                       <td className="py-3 px-6  flex justify-center gap-2">
                                            <button onClick={handleAceept} style={{ fontFamily: 'Inder' }} className="bg-green-700 hover:bg-green-800 text-white px-6 py-1 max-sm:py-2 rounded-lg" >
                                                Accept
                                            </button>
                                            <button onClick={handelDelete} style={{ fontFamily: 'Inder' }} className="bg-red-600 max-sm: hover:bg-red-700 text-white  px-6 py-1 max-sm:py-2 rounded-lg">
                                               Reject
                                            </button>
                                        </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  )}
                  {selectedSection === "approved" && (
                     <div>
                        <div className="lg:flex sm:flex justify-between">
                           <div>
                              <h2 className="text-xl max-sm:text-lg font-bold max-sm:font-medium mb-2 max-sm:mb-0">Approved Leaves Request</h2>
                              <p className="mb-6 max-sm:mb-2 text-gray-600 max-sm:text-[12px]">This is sub description</p>
                           </div>
                           <div className="flex max-sm:overflow-scroll scrollbar-hide space-x-3 max-sm:space-x-1 mb-6 font-medium text-[14px] max-sm:text-[10px]">
                              <div className="relative">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Filter By Employee</option>
                                    <option>new Employee</option>
                                    <option>Old employee</option>
                                 </select>
                              </div>
                              <div className="relative">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Filter By Group</option>
                                    <option>Construction Group</option>
                                    <option>Electric Group</option>
                                 </select>
                              </div>
                              <div className="relative">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Date</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* Table data */}
                        <div className="mt-6">
                           <div className="overflow-x-auto">
                              <table className="w-full bg-white">
                                 <thead className="bg-gray-100">
                                    <tr>
                                       <th className="py-3 px-6 max-sm:px-14">S.NO</th>
                                       <th className="py-3 px-6 max-sm:px-14">Emp Name</th>
                                       <th className="py-3 px-6 max-sm:px-14">Group</th>
                                       <th className="py-3 px-6 max-sm:px-14">Leave Dates</th>
                                       <th className="py-3 px-6 max-sm:px-14">No of days</th>
                                       <th className="py-3 px-6 max-sm:px-14">Reason</th>
                                       <th className="py-3 px-6 max-sm:px-14">Attachments</th>
                                       {/* <th className="py-3 px-6 max-sm:px-14">Actions</th> */}
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr className="border-b text-center">
                                       <td className="py-3 px-6">1</td>
                                       <td className="py-3 px-6">Rahul</td>
                                       <td className="py-3 px-6">Construction</td>
                                       <td className="py-3 px-6">01-09-24 to 10-09-24</td>
                                       <td className="py-3 px-6">10</td>
                                       <td className="py-3 px-6 underline"> <a >View</a></td>
                                       <td className="py-3 px-6 underline"> <a >Details</a></td>
                                       {/* <td className="py-3 px-6 flex justify-center gap-2">
                                            <button style={{ fontFamily: 'Inder' }} className="bg-red-600 hover:bg-red-700 text-white px-6 py-1 rounded-lg">Reject</button>
                                       </td> */}
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  )}
                  {selectedSection === "rejected" && (
                     <div>
                        <div className="lg:flex sm:flex justify-between">
                           <div>
                              <h2 className="text-xl max-sm:text-lg font-bold max-sm:font-medium mb-2 max-sm:mb-0">Rejected Leaves Request</h2>
                              <p className="mb-6 max-sm:mb-2 text-gray-600 max-sm:text-[12px]">This is sub description</p>
                           </div>
                           <div className="flex max-sm:overflow-scroll scrollbar-hide space-x-3 max-sm:space-x-2 mb-6 font-medium text-[14px] max-sm:text-[12px]">
                              <div className="relative">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Filter By Employee</option>
                                    <option>new Employee</option>
                                    <option>Old employee</option>
                                 </select>
                              </div>
                              <div className="relative">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Filter By Group</option>
                                    <option>Construction Group</option>
                                    <option>Electric Group</option>
                                 </select>
                              </div>
                              <div className="relative">
                                 <select className="appearance-none border border-black px-4 py-1 rounded-3xl focus:outline-none">
                                    <option>Date</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                        {/* Table data */}
                        <div className="mt-6">
                           <div className="overflow-x-auto">
                              <table className="w-full bg-white">
                                 <thead className="bg-gray-100">
                                    <tr>
                                       <th className="py-3 px-6 max-sm:px-14">S.NO</th>
                                       <th className="py-3 px-6 max-sm:px-14">Emp Name</th>
                                       <th className="py-3 px-6 max-sm:px-14">Group</th>
                                       <th className="py-3 px-6 max-sm:px-14">Leave Dates</th>
                                       <th className="py-3 px-6 max-sm:px-14">No of days</th>
                                       <th className="py-3 px-6 max-sm:px-14">Reason</th>
                                       <th className="py-3 px-6 max-sm:px-14">Attachments</th>
                                       {/* <th className="py-3 px-6 max-sm:px-14">Actions</th> */}
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr className="border-b text-center">
                                       <td className="py-3 px-6">1</td>
                                       <td className="py-3 px-6">Shiv</td>
                                       <td className="py-3 px-6">Construction</td>
                                       <td className="py-3 px-6">01-09-24 to 10-09-24</td>
                                       <td className="py-3 px-6">10</td>
                                       <td className="py-3 px-6 underline"> <a>View</a></td>
                                       <td className="py-3 px-6 underline"> <a>Details</a></td>
                                       {/* <td className="py-3 px-6 flex justify-center gap-2">
                                            <button style={{ fontFamily: 'Inder' }} className="bg-red-600 hover:bg-red-700 text-white px-6 py-1 rounded-lg">Reject</button>
                                       </td> */}
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                     
                  )}
                   {/* Watermark */}
               {/* <div className="absolute inset-0 flex items-center justify-center">
                  <div className="transform -rotate-45 text-9xl text-gray-500 bg-blue-100 shadow-md opacity-40">
                     In Progress
                  </div>
               </div> */}
               </div>

              
            </div>
         </div>
      </div>
   );
}

export default Leaves;
