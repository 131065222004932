// import * as React from 'react';
// import dayjs from 'dayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
// import TextField from '@mui/material/TextField'; 

// export default function TimePicker({ value, onChange }) {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <MobileTimePicker
//         value={value}
//         onChange={onChange}
//         renderInput={(params) => <TextField {...params} />}
//       />
//     </LocalizationProvider>
//   );
// }
// import * as React from 'react';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { TextField } from '@mui/material';

// export default function TimePicker2({ value, onChange }) {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer components={['TimePicker']}>
//         <TimePicker label="Select Time"
//            value={value}
//           format="HH:mm"
//           onChange={onChange}
//           renderInput={(params) => <TextField {...params} />}
//         />
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }

import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { TextField } from '@mui/material';

export default function TimePicker3({ value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={['StaticTimePicker']}
      >
        <DemoItem>
          <MobileTimePicker
            value={value}
            onChange={onChange}
            format="HH:mm"
            ampm={false}  // Disable AM/PM to show in 24-hour format
            renderInput={(params) => <TextField {...params} />}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}

