import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
// import { ToastContainer, toast } from 'react-toastify';
function CalenderBox({
  dates,
  shiftListData,
  // repeatAfter,
  // setRepeatAfter,
  customDates,
  setCustomDates,
  isEditingDate,
  setIsEditingDate,
  isEditingSchedule,
  setIsEditingSchedule,
  selectedShift,
  shift,
  setShift,
  isScheduled,
  groupId,
  schedule,
  setShiftListData,
  setMonth,
  staticShiftListData,
  shiftListData2,
  setShiftListData2,
  month,
  shiftListData3,
  totalMonthDays,
}) {
  const [shiftStartFrom, setShiftStartFrom] = useState("");
  const [shiftEndOn, setShiftEndOn] = useState("");
  const [repeatFrom, setRepeatFrom] = useState("");
  const [repeatAfter, setRepeatAfter] = useState(5);
  const [shiftSmallDate, setShiftSmallDate] = useState("");
  const [shiftLargeDate, setShiftLargeDate] = useState("");
  // const [staticShiftListData,setStaticShiftListData]=useState([])
  // let repeatAfter= localStorage.getItem("repeatAfter")
  const [counter, setCounter] = useState(0);
  const hasRunRef = useRef(false);
  
  const [timeToRun, setTimeToRun] = useState(
    Math.ceil(
      totalMonthDays.length / (shiftListData3.length + Number(repeatAfter))
    )
  );
  // console.log(shift)

  const updateShiftDates = async () => {
    // setRepeatAfter(schedule?.repeatAfter);
    // console.log(repeatAfter)
    if (repeatAfter !== null || repeatAfter !== 0) {
      if (shiftListData2.length > 0) {
        // Extract all dates and parse them to validate
        const allDates = shiftListData2
          .map((shift) => {
            const dateKey = Object.keys(shift)[0];
            const parsedDate = new Date(dateKey);
            return !isNaN(parsedDate) ? dateKey : null; // Include only valid dates
          })
          .filter(Boolean); // Remove invalid dates

        // Ensure we have valid dates before proceeding
        if (allDates.length > 0) {
          allDates.sort((a, b) => new Date(a) - new Date(b));

          setShiftStartFrom(allDates[0]);
          setShiftEndOn(allDates[allDates.length - 1]);

          const lastDate = new Date(allDates[allDates.length - 1]);
          setRepeatFrom(addDaysToDate(lastDate, Number(repeatAfter) + 1));
          let currentRepeatFrom = addDaysToDate(
            lastDate,
            Number(repeatAfter) + 1
          );
          const updatedShiftListData = [];

          const newDates = await repeatDates(
            shiftListData3,
            currentRepeatFrom,
            1
          );
          console.log(newDates);
          
          setShiftListData2([...shiftListData2, ...newDates]);
          // Assuming addDaysToDate exists
        }
      }
    }

    // Local variable to hold repeatFrom value
  };

  useEffect(() => {
    if ((schedule && !hasRunRef.current) || month === month) {
      const runUpdate = async () => {
        for (let i = 0; i < 5; i++) {
          console.log("hello");

          await updateShiftDates(); // Run updateShiftDates function

          // Log the shift data after each update
          console.log(shiftListData2);
        }
      };

      runUpdate(); // Run update function asynchronously

      hasRunRef.current = true; // Set the flag to true to prevent rerunning the effect
    }
  }, [schedule, month, isScheduled]);

  const getShiftColor = (date) => {
    const customDateEntry = customDates.find(
      (customDate) => Object.keys(customDate)[0] === date
    );
  
    if (customDateEntry) {
      const customShiftName = Object.values(customDateEntry)[0];
      switch (customShiftName) {
        case "morning":
          return "bg-[#009011]"; // Green for morning
        case "afternoon":
          return "bg-[#FAC710]"; // Yellow for afternoon
        case "night":
          return "bg-[#FD474D]"; // Red for night
        case "holiday":
          return "bg-gray-500"; // Gray for holiday
        default:
          return "bg-[#25CCF7]"; // Default color
      }
    }
  
    // Fall back to shiftListData2 if no entry in customDates
    const shiftEntry = shiftListData2.find(
      (shift) => Object.keys(shift)[0] === date
    );
  
    if (!shiftEntry) return ""; // No color if date is not in any shift
  
    const shiftName = Object.values(shiftEntry)[0];
    switch (shiftName) {
      case "morning":
        return "bg-[#009011]"; // Green for morning
      case "afternoon":
        return "bg-[#FAC710]"; // Yellow for afternoon
      case "night":
        return "bg-[#FD474D]"; // Red for night
      case "holiday":
        return "bg-gray-500"; // Gray for holiday
      default:
        return "bg-[#25CCF7]"; // Default color
    }
  };

  const getShiftColor2 = (date) => {
    const shiftEntry = shift.find((shift) => Object.keys(shift)[0] === date);
    if (!shiftEntry) return ""; // No color if date is not in any shift

    const shiftName = Object.values(shiftEntry)[0];
    switch (shiftName) {
      case "morning":
        return "bg-[#009011]";
      case "afternoon":
        return "bg-[#FAC710]";
      case "night":
        return "bg-[#FD474D]";
      default:
        return "bg-[#25CCF7]"; // Default color for any other shift
    }
  };

  function addDaysToDate(date, count) {
    const newDate = new Date(date); // Convert the date string or Date object to a Date object
    if (isNaN(newDate.getTime())) {
      console.error("Invalid date:", date);
      return null; // or handle error accordingly
    }
    newDate.setDate(newDate.getDate() + count); // Add days to the date
    return newDate; // Return the new Date object
  }

  function repeatDates(originalDates, repeatFrom, count) {
    // Convert originalDates format to an array of date strings and shifts
    const dateShiftArray = originalDates.map((obj) => ({
      date: Object.keys(obj)[0],
      shift: Object.values(obj)[0],
    }));

    const newDates = dateShiftArray.map(({ date, shift }) => {
      const originalDate = new Date(date);
      const diffInTime =
        originalDate.getTime() - new Date(dateShiftArray[0].date).getTime(); // Difference in milliseconds
      const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert milliseconds to days

      const newDate = new Date(repeatFrom);
      newDate.setDate(newDate.getDate() + diffInDays); // Add the difference in days
      const formattedDate = newDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD

      return { [formattedDate]: shift }; // Return in the original format with date key and shift value
    });

    return newDates;
  }

  function handleDateClick(dates) {
    if (selectedShift === "") {
      toast.error("Select shift first");
    } else {
      const newShift = { [dates]: selectedShift };

      setCustomDates((prevData) => {
        // Check if the shift for the given date already exists
        const shiftIndex = prevData.findIndex(
          (shift) => Object.keys(shift)[0] === dates
        );
      
        // Update the data accordingly
        let updatedData;
      
        if (shiftIndex !== -1) {
          // If shift exists, update the shift to "holiday"
          updatedData = [...prevData];
          updatedData[shiftIndex] = { [dates]: selectedShift };
        } else {
          // If shift doesn't exist, add the new shift
          updatedData = [...prevData, newShift];
        }
      
        // Sort the updated array by date
        updatedData.sort(
          (a, b) => new Date(Object.keys(a)[0]) - new Date(Object.keys(b)[0])
        );
      
        console.log("Updated Data:", updatedData);
      
        return updatedData;
      });
    }
  }

  function handleRightClick(e,dates){
    e.preventDefault();
  const result=  window.confirm("Are you sure to mark this date as holiday?") 
  if(result){
    const newShift = { [dates]: "holiday" };

    setCustomDates((prevData) => {
      // Check if the shift for the given date already exists
      const shiftIndex = prevData.findIndex(
        (shift) => Object.keys(shift)[0] === dates
      );
    
      // Update the data accordingly
      let updatedData;
    
      if (shiftIndex !== -1) {
        // If shift exists, update the shift to "holiday"
        updatedData = [...prevData];
        updatedData[shiftIndex] = { [dates]: "holiday" };
      } else {
        // If shift doesn't exist, add the new shift
        updatedData = [...prevData, newShift];
      }
    
      // Sort the updated array by date
      updatedData.sort(
        (a, b) => new Date(Object.keys(a)[0]) - new Date(Object.keys(b)[0])
      );
    
      console.log("Updated Data:", updatedData);
    
      return updatedData;
    });
      
  }
  }

  return (
    <>
      {isEditingDate?
      
        <div
          key={dates}
          onClick={() => handleDateClick(dates)}
          onContextMenu={(e)=>{handleRightClick(e,dates)}}
          className={`flex w-[55px] cursor-pointer h-[55px] items-center justify-center border-2 border-black ${getShiftColor(
            dates
          )}`} // Dynamic background color
        >
          {dates === " " ? <div> </div> : new Date(dates).getDate()}
        </div> 
        :
        <>
        <div>
        <div
          key={dates}
          // onClick={() => handleDateClick(dates)}
          className={`flex w-[55px] cursor-pointer h-[55px] items-center justify-center border-2 border-black ${getShiftColor(
            dates
          )}`} // Dynamic background color
        >
          {dates === " " ? <div> </div> : new Date(dates).getDate()}
         
        </div>
        
        </div>

         
       </>
    }
        
{/* <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">

</div> */}
       
       
    </>
  );
}

export default CalenderBox;
