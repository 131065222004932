import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../Components/Navbar/navbar";
import SideMenu from "../../Components/Sidebar/sidebar";
import SendIcon from "@mui/icons-material/Send";
import AllChats from "../../Components/AllChats";
import { url } from "../../urlConfig";


function Chats() {
  const [chatData, setChatData] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const messagesEndRef = useRef(null);
  const socketRef = useRef(null);

  const { receiverId } = useParams();
  const token = localStorage.getItem("authToken");
  const adminId = localStorage.getItem("theAdminId");

  // Helper function to safely extract the user ID from the token
  const getUserIdFromToken = (token) => {
    if (!token) {
      console.error("Auth token is missing.");
      return null;
    }

    try {
      const payload = JSON.parse(atob(token.split(".")[1])); // Decode the JWT payload
      const userId = payload?.admin?._id; // Adjust for nested structure
      if (!userId) {
        console.error("User ID (_id) is missing in token payload:", payload);
        return null;
      }
      return userId;
    } catch (err) {
      console.error("Error decoding token:", err);
      return null;
    }
  };

  // Establish WebSocket connection
 // Establish WebSocket connection
useEffect(() => {
  if (!token) return;

  const userId = getUserIdFromToken(token);
  if (!userId) {
    console.error("Unable to establish WebSocket: Invalid user ID.");
    return;
  }

  socketRef.current = new WebSocket(`ws://159.65.153.38:5000/?userId=${userId}`);

  socketRef.current.onopen = () => {
    console.log("WebSocket connection established.");
  };

  socketRef.current.onmessage = (event) => {
    const message = JSON.parse(event.data);
  
    console.log("Received message via WebSocket:", message);
  
    // Add the message to the state
    setMessages((prevMessages) => [...prevMessages, message]);
  
    // Update the chatData list to reflect the latest message for the correct sender
    setChatData((prevChatData) =>
      prevChatData.map((chat) =>
        chat._id === message.sender
          ? {
              ...chat,
              messages: [...(chat.messages || []), message], // Add the new message
            }
            
          : chat
          
      )
    );
  };
  

  socketRef.current.onclose = () => {
    console.log("WebSocket disconnected.");
  };

  socketRef.current.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  return () => {
    if (socketRef.current) {
      socketRef.current.close();
      console.log("WebSocket connection closed.");
    }
  };
}, [token, selectedChat]);


  // Fetch previous messages for the selected chat

  const fetchMessagesForChat = (receiverId) => {
     const userId = JSON.parse(atob(token.split('.')[1])).id;
    fetch(`http://159.65.153.38:5000/api/chat/${receiverId}?userId=${adminId}`,{
      method:"GET"
    }).then((res)=>{return res.json()})
    .then(response=>{
      console.log(response)
       setMessages(response)

    })
  };
  
  useEffect(()=>{
  },[receiverId])
  

  // Fetch employee chat data
  useEffect(() => {
    if (!adminId || !token) return;

    console.log(`Fetching employees data for adminId: ${adminId}`);
    fetch(`${url}/all-employees/${adminId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status) {
          console.log("Employee chat data fetch ed:", result.data);
          setChatData(result.data);
        } else {
          console.error("Error fetching employees:", result.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
  }, [adminId, token]);

  // Send a message
  const handleSendMessage = () => {
    if (newMessage.trim() === "" || !socketRef.current) {
      console.log("Message is empty or WebSocket is not connected.");
      return;
    }

    const userId = getUserIdFromToken(token);
    if (!userId) {
      console.error("Invalid user ID from token.");
      return;
    }

    if (!selectedChat || !selectedChat._id) {
      console.error("Receiver (selected chat) is invalid.");
      return;
    }

    const message = {
      sender: userId,
      receiver: selectedChat._id,
      content: newMessage,
      timestamp: new Date()
    };

    console.log("Sending message:", message);

    try {
      socketRef.current.send(JSON.stringify(message));
      setMessages((prevMessages) => [...prevMessages, message]);
      console.log("Message sent successfully.");
      setNewMessage("");
    } catch (err) {
      console.error("Error sending message:", err);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      console.log("Enter key pressed, sending message...");
      handleSendMessage();
    }
  };

  // Scroll to the latest message
  useEffect(() => {
    if (messagesEndRef.current) {
      console.log("Scrolling to latest message...");
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const filteredChats = chatData.filter((chat) =>
    chat.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex w-full h-screen">
      <SideMenu />
      <div className="lg:w-[81%] max-sm:w-[100%] sm:w-[100%] overflow-scroll scrollbar-hide">
        <div className="ml-3 max-sm:hidden">
          <div className="mt-10 sticky top-0 max-sm:top-10">
            <Navbar />
          </div>

          {/* Chats Header */}
          <div className="flex lg:-ml-5 max-sm:hidden">
            <h1 className="text-[22px] font-semibold lg:ml-8 mt-2 mr-3">Chats</h1>
            <input
              className="w-[400px] h-12 rounded-xl bg-[#f5f3f3] outline-none pl-4 mb-5"
              placeholder="Search Profile..."
              value={searchTerm}
              onChange={(e) => {
                console.log("Search term changed:", e.target.value);
                setSearchTerm(e.target.value);
              }}
            />
          </div>

          {/* Chat List and Messages */}
          <div className="lg:flex max-sm:hidden sm:flex lg:p-4 lg:-mt-2 h-full">
  {/* Chat List */}
  <div className="lg:w-[40%] sm:w-[50%] overflow-y-scroll pr-2  h-[445px] space-y-4">
    
    {filteredChats.map((chat) => (
      <div
        key={chat._id}
        onClick={() => {
          console.log("Selected chat:", chat);
          setSelectedChat(chat); // Update selected chat
          fetchMessagesForChat(chat._id); // Fetch messages for the selected profile
        }}
        className={`cursor-pointer rounded-xl py-1 px-1 bg-[#EDEDED] ${
          selectedChat?._id === chat._id ? "bg-[#a8d6db]" : ""
        }`}
      >
        <AllChats
        
          name={chat.name}
          messages={chat.messages || []}
          profileImage={chat.profileImage || "/wtspProfile.png"}
          unreadCount={chat.unreadCount || 0}
          time={
            chat.messages?.[chat.messages.length - 1]?.time
              ? new Date(chat.messages[chat.messages.length - 1].time).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : ""
          }
        />
      </div>
    ))}
  </div>
  <div className="border max-sm:hidden border-gray-300 h-auto "></div>

  {/* Messages */}
  {selectedChat ? (
    <div className="w-[60%] h-[58vh] lg:pl-3 flex flex-col justify-between">
      {/* Messages List */}
      <div className="overflow-y-auto scrollbar-hide h-[90vh]">
      {messages.map((msg, index) => {
  // Directly parse timestamp
  const timestamp = msg.timestamp
    ? new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    : "";

  return (
    <div
      key={index}
      className={`flex ${msg.sender === getUserIdFromToken(token) ? "justify-end" : "justify-start"}`}
    >
      <div
        className={`px-4 py-1 mb-3 max-w-[45%] ${
          msg.sender === getUserIdFromToken(token)
            ? "bg-[#b2f5dc] text-black rounded-tl-xl rounded-b-xl" // Sent message (current user)
            : "bg-[#a0bbe6] text-black rounded-tr-xl rounded-b-xl" // Received message (from other user)
        }`}
        style={{ wordWrap: "break-word" }}
      >
        <p>{msg.content}</p>
        <p className="text-[12px] text-gray-500 mt-0 text-right">{timestamp}</p>
      </div>
    </div>
  );
})}


        <div ref={messagesEndRef} />
      </div>

      {/* Message Input */}
      <div className="flex px-3 fixed bottom-2 w-[45%]   rounded-xl items-center bg-[#EDEDED] border-gray-300 ">
              <input
                type="text"
                placeholder="Type your message here..."
                className="flex-grow  outline-none py-4 h-full md:w-[440px] bg-[#EDEDED] lg:w-[570px]"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button
                className="p-2 bg-blue-500 text-white rounded-full"
                onClick={handleSendMessage}
              >
                <SendIcon />
              </button>
            </div>

    </div>
  ) : (
    <div className="w-[60%] h-[60vh] lg:pl-3 flex justify-center items-center">
      <p>Please Select a employee to start chat.</p>
    </div>
  )}
</div>

        </div>
      </div>
    </div>
  );
}

export default Chats;
