import React, { useState, useEffect } from "react";

function AllChats({ name, messages = [], profileImage, unreadCount }) {
  const [lastMessageTime, setLastMessageTime] = useState("");

  // Function to truncate the message
  const truncateMessage = (msg, wordLimit) => {
    const words = msg.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : msg;
  };

  // Get the time of the last message
  useEffect(() => {
    if (messages && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      setLastMessageTime(lastMessage.time);
    }
  }, [messages]); // This effect runs whenever the messages change

  return (
    <div className="flex cursor-pointer hover:shadow-lg bg-[#EDEDED] rounded-xl py-1 px-2 lg:w-[100%]">
      <img src={profileImage || "/wtspProfile.png"} className="w-14 h-14 rounded-full"  />
      <div className="ml-5 mt-1 lg:w-[40%] md:w-[60%] max-sm:w-full">
        <h1 className="font-medium">{name}</h1>
        <p className="text-[14px] font-light">
          {truncateMessage(messages[messages.length - 1]?.text || "", 4)}
        </p>
      </div>
      <div className="">
        {/* Show unread count if any unread messages */}
        {unreadCount > 0 && (
          <p className="bg-[#43C595] rounded-full text-white text-center w-6 md:ml-40">
            {unreadCount}
          </p>
        )}
        {/* Display the last message time */}
        <p className="text-[#43C595] block text-right mt-2 max-sm:text-sm">
          {lastMessageTime }
        </p>
      </div>
    </div>
  );

}


export default AllChats;
