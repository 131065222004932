import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useLocation } from 'react-router-dom';

function Navbar() {
    const profileImage = localStorage.getItem("profile");
    const contactPerson = localStorage.getItem("contactPerson") || "Admin";
    const email = localStorage.getItem("email") || "default@example.com";

    const parsedProfileImage = profileImage ? JSON.parse(profileImage) : null;

    let location = useLocation();
    console.log(location);


    return (
        <>
            <div className='sticky lg:top-0 z-50 max-sm:top-10 sm:top-10 md:mt-10 lg:-mt-10 bg-white'>
                <div className="flex justify-between sm:p-5 lg:p-10 lg:pl-2">
                    <div>
                        <h1 className="text-[22px]" style={{ fontFamily: 'Inder' }}>Welcome Back :)</h1>
                        <p className="text-[14px] mt-2" style={{ fontFamily: 'Inder' }}>Manage and forecast your project online.</p>
                    </div>
                    <div className="flex space-x-2 mr-3 max-sm:hidden cursor-pointer">
                        <img
                            src={parsedProfileImage || "https://cdn-icons-png.flaticon.com/512/8847/8847419.png"}
                            className="h-[40px] w-[40px] rounded-full"
                            alt={"admin profile"}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "https://cdn-icons-png.flaticon.com/512/8847/8847419.png";
                            }}
                        />
                        <div className="text-[14px]">
                            <h1 className="text-blue-700 font-semibold">{contactPerson}</h1>
                            <p>{email}</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default Navbar;
